// stores/currentReservation.js
//guarda la info de las reserva q estan realizando y pendiente de pago

import { defineStore } from 'pinia';
import { Config } from '@/interfaces/Config';
import { Deporte } from '@/interfaces/Deporte';
import { Disponibilidad } from '@/interfaces/Disponibilidad';
import { Reservation } from '@/interfaces/Reservation';
import { ref } from 'vue';


export const currentReservationStore = defineStore('currentReservation',{
  state: ()=>{
        return {
            reservation: ref({} as Reservation | null),
            deportes: ref<Deporte[]>([]),
            config: {} as Config | null,
            disponibilidad: ref<Disponibilidad[]>([]),
        }
  },
  actions:{
  }

}
);